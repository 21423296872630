.not-found-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    overflow: hidden;
    /* No background color */
  }
  
  .not-found-image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden; /* Ensures no overflow */
  }
  
  .not-found-bg-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensures the image covers the entire section */
    object-position: contain; /* Centers the image */
    opacity: 1; /* Ensure the image is fully opaque */
  }
  
  .not-found-text-container {
    position: relative;
    z-index: 2;
    color: #333;
    text-align: center;
    padding: 20px;
    /* Optional: Add a semi-transparent background for better text readability */
  }
  
  .not-found-text-container h1 {
    font-size: 4rem;
    margin-bottom: 10px;
  }
  
  .not-found-text-container p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  