.body{
  font-family: 'Roboto';
}
.focus-section {
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 50px;
  background-color: #ffffff;
}

.focus-title {
  font-size: 32px;
  margin-bottom: 10px;
}

.focus-subtitle {
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
}

.focus-items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
  justify-items: stretch;
  align-items: stretch;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

.focus-item {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center; 
  flex-direction: column;
}

.focus-icon {
  font-size: 36px;
  margin-bottom: 15px;
  display: inline-block;
  color: #1FA6E0;
}

.h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.p {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
}

/* Tablet View */
@media (max-width: 768px) {
  .focus-title {
    font-size: 28px;
  }

  .focus-subtitle {
    font-size: 16px;
  }

    .focus-items {
        grid-template-columns: 1fr;
        gap: 50px;
        margin-right: 30px;
    }

  .focus-item {
    padding: 20px;
  }

  .focus-icon {
    font-size: 32px;
  }

  .h2 {
    font-size: 16px;
  }

  .p {
    font-size: 13px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .focus-title {
    font-size: 24px;
  }

  .focus-subtitle {
    font-size: 14px;
  }

  .focus-items {
    grid-template-columns: 1fr;
    gap: 80px !important;
  }

  .focus-item {
    padding: 15px, 15px,;
  }

  .focus-icon {
    font-size: 38px;
  }

  .focus-items h2 {
    font-size: 18px;
}

  .focus-item p {
    font-size: 14px;
  }
}
