/* ContactFormModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 100%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    font-weight: bold;
    color:rgb(0, 0, 0);
    right: 10px;
    cursor: pointer;
  }
  