.hero-section {
  background-image: url('../assets/HeroSection-hmf.webp');
  background-size: cover;
  background-position: center center;
  padding: 0px 10px 0px 10px;
  text-align: center; /* Center-align text */
  color: #ffffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative;
}

.hero-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.201); /* Semi-transparent overlay */
  z-index: 1;
}

.hero-content {
  z-index: 2; /* Ensure content is above the overlay */
  max-width: 600px; /* Limit content width for readability */
  width: auto; /* Allow width to auto-adjust */
}

.hero-section h1 {
  font-size: 48px; /* Large heading */
  margin-top: 0;
  margin-bottom: 20px;
}

.hero-section h1 span {
  color: #1FA6E0;
}

.hero-section p {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.5; /* Improved readability */
  width: auto;
  max-width: 100%; /* Allow width to auto-adjust */
}

.learn-more-button {
  background-color: #1FA6E0;
  color: #fff;
  border: none;
  padding: 10px 30px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Space between paragraph and button */
}

.learn-more-button:hover {
  background-color: #1480ad;
}

@media (max-width: 768px) {
  .hero-section {
    padding: 1rem;
    height: 90vh; /* Adjust height for smaller screens */
  }

  .hero-section h1 {
    font-size: 29px; /* Smaller heading on mobile */
  }

  .learn-more-button {
    width: 60%; /* Full-width button on mobile */
    padding: 15px 0; /* Adjusted padding for full-width */
  }
}
