.purpose-section {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #ffffff;
}

.purpose-section h3 {
  font-size: 25px;
  max-width: 800px;
  margin: 0 auto 3rem;

}

.purpose-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  
}

.purpose-card {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.purpose-card:hover {
  transform: translateY(-5px);
}

.purpose-card .service-icon {
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
}

.purpose-card h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.purpose-card p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .purpose-section {
    padding: 3rem 1rem;
  }
}
.purpose-section h3 {
  font-size: 20px;
}
.purpose-card h3 {
  font-size: 20px;
  margin-bottom: 1rem;
}