.faq-hero {
    background: url('../assets/FAQHero-hmf.webp') center/cover no-repeat;
    background-size: cover;
    background-position: center center; /* Centers the background image */
    padding: 180px 50px 0px 50px; /* top, right, bottom, left */
    text-align: center;
    color: #ffffff;
    height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative; /* Allows for the overlay positioning */
  }
  
  .faq-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.164); /* Adjust the color and opacity here */
    z-index: 1; /* Ensure the overlay is on top of the background but behind the content */
  }
  
  .faq-hero-content {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 70px;
    position: relative; /* Ensures the content is above the overlay */
    z-index: 2; /* Ensures the content appears above the overlay */
  }
  
  .faq-hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
    color: #1FA6E0;
  }
  
  .faq-hero p {
    font-size: 18px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    line-height: 1.2;
  }
  
  /* Tablet View */
  @media screen and (max-width: 768px) {
    .faq-hero {
      padding: 150px 30px 0px 30px;
      height: 60vh;
      justify-content: center; /* Centering content vertically */
      align-items: center; /* Centering content horizontally */
    }
  
    .faq-hero-content {
      max-width: 100%;
      margin-top: 50px;
    }
  
    .faq-hero h1 {
      font-size: 36px;
    }
  
    .faq-hero p {
      font-size: 16px;
      line-height: 1.4;
    }
  }
  
  /* Mobile View */
  @media screen and (max-width: 480px) {
    .faq-hero {
      padding: 1rem;
      height: 85vh;
    }
  
    .faq-hero-content {
      margin-top: 30px;
      text-align: center; /* Center text for smaller screens */
    }
  
    .faq-hero h1 {
      font-size: 25px;
    }
    
    .faq-hero p {
      font-size: 18px;
      line-height: 1.4;
    }
  }
  