.cta-wrapper {
  background-color: #0B5676;
  padding: 4rem 2rem;

}

.cta-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cta-image {
  flex: 1;
  max-width: 40%;
}

.cta-image img {
  max-width: 100%;
  height: 40vh;
  margin-bottom: -20PX;
  margin-top: -20PX;
}

.cta-content {
  flex: 1;
  color: #ffffff;
  padding-left: 2rem;
}

.cta-content h2 {
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  margin-bottom: 1.5rem;
}

.cta-button {
  background-color: #8dc63f;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #76b530;
}

@media (max-width: 768px) {
  .cta-container {
    flex-direction: column;
  }

  .cta-image {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .cta-content {
    padding-left: 0;
    text-align: center;
  }
}