.body{
    font-family: 'Roboto';
}
.solution-section {
  padding: 4rem 2rem;
  background-color: #fff;
  padding-top: 20PX;
}

.solution-section h2 {
  text-align: center;
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  margin-bottom: 1rem;
}

.solution-section > p {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 3rem;
  font-size: 1.1rem;
}

.solution-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.accordion {
  flex: 1;
  min-width: 300px;
  margin-right: 2rem;
}

.accordion-item {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

.accordion-item.active {
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.accordion-item h3 {
  padding: 1rem;
  cursor: pointer;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-item p {
  padding: 0 1rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
}

.accordion-item.active p {
  max-height: 200px;
  padding: 1rem;
}

.accordion-arrow {
  transition: transform 0.3s ease;
}

.accordion-arrow.up {
  transform: rotate(180deg);
}

.solution-image {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solution-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

@media (max-width: 768px) {
  .solution-content {
    flex-direction: column;
  }
  
  .accordion {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
